import "twin.macro";
import { forwardRef, TextareaHTMLAttributes } from "react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import Text from "app/styles/Text";
import uniqid from "app/lib/uniqid";

type HTMLTextareaType = TextareaHTMLAttributes<HTMLTextAreaElement>;

interface Props extends Omit<HTMLTextareaType, "prefix"> {
  label: string;
  labelHidden?: boolean;
  assistiveText?: string;
  error?: boolean;
  errorMessage?: string;
  containerClassName?: string;
}

function TextAreaField(props: Props, ref: any) {
  const {
    label,
    labelHidden = false,
    id = uniqid(),
    containerClassName,
    assistiveText,
    error,
    disabled,
    errorMessage,
    rows = 4,
    ...rest
  } = props;

  return (
    <Container className={containerClassName}>
      <label htmlFor={id} className={labelHidden ? "hidden" : undefined}>
        {label}
      </label>

      <div tw="flex gap-[8px]">
        <TextareaContainer className={clsx({ error, disabled })}>
          <div>
            <textarea
              rows={rows}
              ref={ref}
              id={id}
              disabled={disabled}
              {...rest}
            />
          </div>
        </TextareaContainer>
      </div>

      {!!assistiveText ? (
        <Text className="assistive-text">{assistiveText}</Text>
      ) : null}

      {!!errorMessage ? (
        <Text className="error-message">{errorMessage}</Text>
      ) : null}
    </Container>
  );
}

export default forwardRef(TextAreaField);

const Container = styled.div`
  ${tw`max-w-full`}

  > label {
    ${tw`block mb-[8px] text-[1.6rem]`}

    &.hidden {
      ${tw`hidden`}
    }
  }

  > p {
    ${tw`mt-[8px]`}

    &.assistive-text {
      ${tw` text-black60 dark:text-white60`}
    }

    &.error-message {
      ${tw`text-error70 dark:text-error50`}
    }
  }
`;

const TextareaContainer = styled.div`
  ${tw`w-full px-[16px] py-[10px] rounded-[4px] border border-[#0000001F] focus-within:border-black transition-colors flex-grow`}

  ${tw`dark:border-white30 dark:focus-within:border-white`}

  &.error {
    ${tw`border-error70 dark:border-error50`}
  }

  &.disabled {
    ${tw`bg-[#F2F2F2] border-[#E5E5E5] text-[#B2B2B2] dark:bg-[#0D0D0D] dark:border-[#1A1A1A] dark:text-[#4D4D4D]`}
  }

  > div {
    ${tw`flex items-center gap-[12px]`}
  }

  textarea {
    ${tw`w-full bg-transparent outline-none resize-none text-[1.6rem] placeholder:text-black32`}

    ${tw`dark:placeholder:text-white32`}

    &:disabled {
      ${tw`cursor-not-allowed`}
    }

    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
    }

    &[data-autocompleted] {
      background-color: transparent !important;
    }
  }
`;
