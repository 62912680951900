import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import Account from "app/models/account";
import SearchCurrency from "app/models/currency";
import Book from "app/models/book";
import { SOCKET_URL } from "app/constants/variables";
import { useToken } from "app/providers/TokenProvider";
import { SocketMessage } from "app/models/socket";
import {
  ACCOUNTS_REQUEST_QUERY,
  BOOKS_REQUEST_QUERY,
  SEARCH_CURRENCIES_REQUEST_QUERY,
  CONNECT_TRADE_REQUEST_QUERY,
  FIAT_DEPOSIT_STATUS_QUERY,
} from "app/constants/commonQueries";
import { GenericResponse } from "app/api/types";
import { FiatDepositStatus } from "app/api/fiat_deposits/types";

function useSocket() {
  const tokens = useToken();

  const url = `${SOCKET_URL}/subscribe?jwt=${tokens?.access_token}`;
  const queryClient = useQueryClient();

  const parseMessage = React.useCallback(
    (message: SocketMessage) => {
      const { event_name, data } = message;
      if (event_name === "balances") {
        //Update account query
        queryClient.setQueryData(
          [ACCOUNTS_REQUEST_QUERY],
          (d?: AxiosResponse<GenericResponse<Account[]>>) => {
            if (d !== undefined) {
              return {
                ...d,
                data: {
                  ...d.data,
                  data,
                },
              };
            }

            return d;
          }
        );
      } else if (event_name === "currency") {
        queryClient.setQueryData(
          [SEARCH_CURRENCIES_REQUEST_QUERY],
          (d?: Map<string, SearchCurrency[]>) => {
            let currencies = d as Map<string, SearchCurrency[]>;
            if (!d) {
              currencies = new Map<string, SearchCurrency[]>();
            }

            currencies.set(
              data["counter_currency"].toLowerCase(),
              data["currencies"]
            );
            return currencies;
          }
        );
      } else if (event_name === "connect_trade_status") {
        const tradeId = data.id;
        queryClient.invalidateQueries([CONNECT_TRADE_REQUEST_QUERY, tradeId]);
      } else if (event_name === "book") {
        queryClient.setQueryData(
          [BOOKS_REQUEST_QUERY],
          (d?: AxiosResponse<Book[]>) => {
            if (d) {
              return {
                ...d,
                data: data as any,
              };
            }

            return d;
          }
        );
        if (!queryClient.isFetching([ACCOUNTS_REQUEST_QUERY])) {
          queryClient.invalidateQueries([ACCOUNTS_REQUEST_QUERY]);
        }
      } else if (event_name === "fiat_deposit_status") {
        const depositData = data as FiatDepositStatus;
        queryClient.setQueryData(
          [FIAT_DEPOSIT_STATUS_QUERY],
          (d?: FiatDepositStatus) => {
            return {
              ...d,
              ...depositData,
            };
          }
        );
      }
    },
    [queryClient]
  );

  React.useEffect(() => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
      console.log("Web socket connected");
      ws.send(JSON.stringify({ event_name: "balances" }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      parseMessage(data);
    };

    ws.onerror = (err) => {
      console.log({ err });
    };

    return () => ws.close();
  }, [parseMessage, url]);
}

export default useSocket;
