import { PropsWithChildren } from "react";

import { gotoLogin } from "app/lib/logout";
import useGetProfile from "app/hooks/useGetProfile";
import useRefreshUserSession from "app/hooks/useRefreshUserSession";
import useIsLoggedIn from "app/hooks/useAuth";
import LoaderContainer from "./ui/LoaderContainer";
import NetworkLoader from "./ui/NetworkLoader";
import useGetProfiles from "app/hooks/useGetProfiles";

export default function AuthGuard(props: PropsWithChildren) {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    gotoLogin();
  }

  const profileQuery = useGetProfile(isLoggedIn);

  const profilesQuery = useGetProfiles(isLoggedIn);

  useRefreshUserSession();

  // const loading = isInitialLoading || (refreshingSession && !profile);

  const onRetry = () => {
    if (profileQuery.error) {
      profileQuery.refetch();
    }

    if (profilesQuery.error) {
      profilesQuery.getProfiles();
    }
  };

  return (
    <>
      {profileQuery.isFetching || profilesQuery.isFetching ? (
        <NetworkLoader page={false} />
      ) : null}

      <LoaderContainer
        screen
        loading={profileQuery.isLoading || profilesQuery.isLoading}
        error={!!profileQuery.error || !!profilesQuery.error}
        onRetry={onRetry}
      >
        {props.children}
      </LoaderContainer>
    </>
  );
}
