import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { ReactComponent as LogoDark } from "app/assets/images/logo-dark.svg";
import { ReactComponent as LogoWhite } from "app/assets/images/logo-white.svg";
import { useAppTheme } from "app/providers/AppThemeProvider";
import useGoBack from "app/hooks/useGoBack";
import Button from "../ui/Button";

export default function FullPageLayout({ children }: PropsWithChildren) {
  const theme = useAppTheme();

  const goBack = useGoBack();

  const logo =
    theme === "dark" ? <LogoWhite tw="h-[20px]" /> : <LogoDark tw="h-[20px]" />;

  return (
    <div tw="w-screen min-h-dvh h-full flex flex-col">
      <header tw="flex items-center justify-between border-b border-b-strokeMid dark:border-b-white12 px-[24px] py-[14px]">
        {logo}

        <Button tertiary small onClick={() => goBack()}>
          Exit
        </Button>
      </header>

      <main tw="grow pt-[32px] pb-[23px] px-[16px] sm:py-[120px] sm:px-[32px]">
        <Outlet />
        {children}
      </main>
    </div>
  );
}
